import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import produitsData from '../data.json';
import './CheckoutForm.css';

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [paymentResult, setPaymentResult] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [discountCode, setDiscountCode] = useState('');
    const [productDetails, setProductDetails] = useState(null);
    const productDetails2 = produitsData.produits.find(p => p.id === parseInt(id, 10));

    useEffect(() => {
        if (location.state && location.state.productDetails) {
            setProductDetails(location.state.productDetails);
        } else if (productDetails2) {
            setProductDetails(productDetails2);
        }
    }, [location.state, productDetails2]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);

        if (!stripe || !elements) {
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardElement);
        if (!cardElement) {
            setLoading(false);
            setPaymentResult({ success: false, message: 'Erreur avec les détails de la carte.' });
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name,
                email,
                address: {
                    line1: address,
                },
            },
        });

        if (error) {
            console.error(error);
            setLoading(false);
            setPaymentResult({ success: false, message: error.message });
            return;
        }

        try {
            const { data } = await axios.post('http://localhost:3001/create-checkout-session', {
                productDetails: {
                    ...productDetails,
                    discountCode: discountCode, // Ajoutez le code de réduction ici
                    id: paymentMethod.id,
                },
            });

            const sessionId = data.id;

            const { error: redirectError } = await stripe.redirectToCheckout({ sessionId });

            if (redirectError) {
                console.error(redirectError);
                setLoading(false);
            } else {
                setPaymentResult({ success: true });
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            setPaymentResult({ success: false, message: "Une erreur s'est produite lors du paiement." });
        }
    };

    return (
        <div className="payment-container">
            <h2>Récapitulatif de la commande</h2>
            
            {productDetails2 ? (
                <div className="order-details">
                    <p><strong>Produit:</strong> {productDetails2.title}</p>
                    <p><strong>Prix:</strong> {productDetails2.discountPrice} EUR</p>
                </div>
            ) : (
                <p>Chargement des détails du produit...</p>
            )}

            <form className="payment-form" onSubmit={handleSubmit}>
                <label htmlFor="name">Nom</label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />

                <label htmlFor="email">Email</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />

                <label htmlFor="address">Adresse de facturation</label>
                <input
                    type="text"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                />

                <label htmlFor="discount-code">Code de réduction</label>
                <input
                    type="text"
                    id="discount-code"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                />

                <label htmlFor="card-element">Détails de la carte</label>
                <div className="card-element">
                    <CardElement options={{ style: { base: { fontSize: '16px' } } }} />
                </div>

                <button type="submit" disabled={!stripe || loading}>
                    {loading ? 'Paiement en cours...' : 'Payer maintenant'}
                </button>
            </form>

            {paymentResult && (
                <div className={`payment-result ${paymentResult.success ? 'success' : 'error'}`}>
                    <p>{paymentResult.message}</p>
                </div>
            )}
        </div>
    );
};

export default CheckoutForm;
