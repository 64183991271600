import React from 'react';
import './About.css';
import { Link } from 'react-router-dom';
import backgroundImg from '../baground_section_about.webp'; // Chemin vers votre image de fond
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t, i18n } = useTranslation();
  return (
    <section className="about-section" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="container">
        <h2 className="section-title">{t('about-us.title')}</h2>
        <div className="about-content">
            <p>
                {t("about-us.desc1")}
            </p>
            <p>
                {t("about-us.desc2")}
            </p>
            <p>
                {t("about-us.desc3")}
            </p>
            <br />
            <div className="button-about">
                <Link to='/portfolio'>{t('about-us.btn')}</Link>
            </div>       
        </div>

        <div className="vision-section">
          <h3>{t('about-us.title-vison')}</h3>
          <p>
            {t("about-us.desc-vision")}
          </p>
        </div>

        <div className="skills-section">
          <h3>{t('about-us.title-skills')}</h3>
          <ul>
            <li>Web Design</li>
            <li>Developpement Web</li>
            <li>Developpement Fivem</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default About;
