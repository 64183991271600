import React from "react";
import './PatchNote.css';
import backgroundImg from '../baground_section_about.webp';
import produitsData from '../data.json'


const PatchNote = () => {
  const patchNotes = produitsData.patchNotes

    return (
        <div className="patchnote-content" style={{ backgroundImage: `url(${backgroundImg})` }}>
          <h2>Patch Note</h2>
          <div className="patchnote-list">
            {patchNotes.map((note, index) => (
              <div key={index} className="patchnote-item">
                <h3>{note.resourceName}</h3>
                <p><strong>Version:</strong> {note.version}</p>
                <p>{note.description}</p>
                <p><strong>Fichiers modifiés:</strong> {note.filesModified.join(', ')}</p>
                <div className="patchnote-images">
                  {note.images.map((image, imgIndex) => (
                    <img key={imgIndex} src={image} alt={`Bug ${imgIndex + 1}`} />
                  ))}
                </div>
                <p className="patchnote-date">{note.date}</p>
              </div>
            ))}
          </div>
        </div>
    )
}

export default PatchNote;
