import React from "react";
import Portfolio from "../components/portfolio";

const PortfolioS = () => {
    document.title = "Portfolio - NoxZi-Dev";
    return (
        <div>
            <Portfolio/>
        </div>
    )
}

export default PortfolioS;