import React from "react";
import Contact from "../components/contact";

const DevisPage = () => {
    document.title = "Contact - NoxZi-Dev";
    return (
        <div>
            <Contact/>
        </div>
    )
}

export default DevisPage;