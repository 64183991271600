import React from "react";
import FAQItem from "./FAQItem";
import './FAQ.css'

const FAQ = ({ data }) => {
    return (
        <div className="container-FAQ">
            <div className="content-FAQ">
                <h2>FAQ</h2>
                {data.map((item, index) => (
                     <FAQItem key={index} question={item.question} answer={item.answer} />
                ))}
            </div>
        </div>
    )
}

export default FAQ