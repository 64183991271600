import React from 'react';
import Slider from 'react-slick';
import './SectionDevisEtPortfolio.css'; // Fichier CSS pour le style
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link, useLocation } from 'react-router-dom';
import backgroundImg from '../baground_section_contact.webp'; 
import produitsData from '../data.json'
import { useTranslation } from 'react-i18next';
// const { t, i18n } = useTranslation();

const SectionDevisEtPortfolio = () => {
  const { t, i18n } = useTranslation();
  const images = produitsData.devisportfolio

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="devis-portfolio-section" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="devis-section">
        <h2>{t('devis&port.title')}</h2>
        <p>{t('devis&port.desc')}</p>
        <Link to="/contact" className="cta-button">{t('devis&port.btn_cta_demande_devis')}</Link>
      </div>
      
      <div className="portfolio-devis-section">
        <h2>{t('devis&port.titleExempleCrea')}</h2>
        <Slider {...settings}>
          {images.map(image => (
            <div key={image.id} className="portfolio-devis-item">
              <img src={image.src} alt={image.alt} className="portfolio-devis-image" />
            </div>
          ))}
        </Slider>
        <div className="portfolio-devis-buttons">
          <Link to="/portfolio" className="cta-button">{t('devis&port.btn_look_port')}</Link>
          <Link to="/contact" className="cta-button">{t('devis&port.btn_create_devis')}</Link>
        </div>
      </div>
    </div>
  );
}

export default SectionDevisEtPortfolio;
