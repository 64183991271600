import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ProjectDetail.css';
import produitsData from '../data.json';
import backgroundImg from '../baground_section_about.webp';

const ProjectDetail = () => {
    const { name } = useParams();
    const navigate = useNavigate();
    const project = produitsData.portfolio.find(p => p.name === name)
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
        setCurrentImageIndex((currentImageIndex + 1) % project.images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((currentImageIndex - 1 + project.images.length) % project.images.length);
    };

    return (
        <div className="project-content" style={{ backgroundImage: `url(${backgroundImg})` }}>
        <div className="project-detail">
            <button className="back-button" onClick={() => navigate(-1)}>Retour</button>
            <div className="project-detail-content">
                <h2>{project.title}</h2>
                <div className="image-viewer">
                    <button className="nav-button left" onClick={prevImage}>&lt;</button>
                    <img src={project.images[currentImageIndex]} alt={`${project.title} ${currentImageIndex + 1}`} />
                    <button className="nav-button right" onClick={nextImage}>&gt;</button>
                </div>
                <div className="project-description">
                    <p>{project.description}</p>
                </div>
                <div className="project-info">
                    <p><strong>Client:</strong> {project.client}</p>
                    <p><strong>Server/Société:</strong> {project.server}</p>
                    <p><strong>Date de début:</strong> {project.startDate}</p>
                    <p><strong>Date de fin:</strong> {project.endDate}</p>
                    <p><strong>Langage utilisé:</strong> {project.language}</p>
                    <p><strong>Framework:</strong> {project.framework}</p>
                </div>
            </div>
        </div>
    </div>
    );
};

export default ProjectDetail;
