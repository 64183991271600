import React, { useState } from 'react';
import './NavBar.css';
import { Link, useLocation } from 'react-router-dom';
import logo from '../logo.png';
import Lottie from 'lottie-react';
import hamburgerAnimationData from '../Burger-Menu.json';
import { useTranslation } from 'react-i18next';
import frenchFlag from '../french-republic.svg';
import englishFlag from '../united-kingdom.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsDropdownOpen(false);
    localStorage.setItem('language', lng);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <Link to="/" className={location.pathname === '/' ? 'selected' : ''}>
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <div className="navbar-hamburger" style={{ width: '40px', height: '40px' }}>
        <a onClick={toggleMenu}>
          <Lottie animationData={hamburgerAnimationData} />
        </a>
      </div>
      <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <li>
          <Link to="/" onClick={toggleMenu} className={location.pathname === '/' ? 'selected' : ''}>
            {t('NavBar.home')}
          </Link>
        </li>
        <li>
          <Link to="/produits" onClick={toggleMenu} className={location.pathname === '/produits' ? 'selected' : ''}>
            {t('NavBar.products')}
          </Link>
        </li>
        <li>
          <Link to="/portfolio" onClick={toggleMenu} className={location.pathname === '/portfolio' ? 'selected' : ''}>
            {t('NavBar.portfolio')}
          </Link>
        </li>
        <li>
          <Link to="/patch-note" onClick={toggleMenu} className={location.pathname === '/patch-note' ? 'selected' : ''}>
            {t('NavBar.patch-note')}
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={toggleMenu} className={location.pathname === '/contact' ? 'selected' : ''}>
            {t('NavBar.devis')}
          </Link>
        </li>
        <li>
          <a onClick={toggleMenu} href="https://doc.noxzi-dev.fr" target="_blank" rel="noopener noreferrer">
            {t('NavBar.doc')}
          </a>
        </li>
        <li className="navbar-language-dropdown">
          <div onClick={toggleDropdown}>
            <img
              src={i18n.language === 'fr' ? frenchFlag : englishFlag}
              alt={i18n.language === 'fr' ? 'French flag' : 'English flag'}
            />
          </div>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              <div onClick={() => changeLanguage('fr')}>
                <img src={frenchFlag} alt="French flag" />
                FR
              </div>
              <div onClick={() => changeLanguage('en')}>
                <img src={englishFlag} alt="English flag" />
                EN
              </div>
            </div>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
