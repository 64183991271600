import React, { useState } from 'react';
import './contact.css';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundImg from '../baground_section_2.webp';

const Contact = () => {
  const [formType, setFormType] = useState('contact');

  const handleFormSubmit = (e) => {
    e.preventDefault();

    let templateParams = {
      name: e.target.name.value,
      prenom: e.target.prenom.value,
      email: e.target.email.value,
      message: e.target.message?.value,
      project_description: e.target.project_description?.value,
      project_name: e.target.project_name?.value,
      budget: e.target.budget?.value,
      discord: e.target.discord?.value,
      document: e.target.document?.files[0] || null,
      subject: e.target.subject?.value
    };

    // Determine the template ID based on form type
    const templateID = formType === 'contact' ? 'template_q0gfllk' : 'template_h92t3ah';

    emailjs.sendForm('service_21v50ag', templateID, e.target, 'p3GK0M3U0Zudy1jXA')
      .then((result) => {
        toast.success('Votre mail a été envoyé avec succès !');
      }, (error) => {
        toast.error('Une erreur s\'est produite, veuillez réessayer.');
      });

    // Prepare the data for the Discord webhook
    const discordWebhookURL = 'https://discordapp.com/api/webhooks/1262389570948038747/v_Sp5PURE1QHe8Jui--yjOE_XNGevZyyeGZ6uP5vZfEAt73Y90dna-LNcJ4329F6LbEn';

    let discordMessage;
    if (formType === 'contact') {
      discordMessage = {
        content: `Nouveau message de contact de ${templateParams.name} ${templateParams.prenom} (${templateParams.email}):\n
                  Nom: ${templateParams.name} ${templateParams.prenom}\n
                  Sujet: ${templateParams.subject}\n
                  Email: ${templateParams.email}\n
                  Message: ${templateParams.message}\n
                  Discord: ${templateParams.discord}`
      };
    } else if (formType === 'devis') {
      discordMessage = {
        content: `Nouvelle demande de devis de ${templateParams.name} ${templateParams.prenom} (${templateParams.email}):\n
                  Nom: ${templateParams.name} ${templateParams.prenom}\n
                  Nom du projet: ${templateParams.project_name}\n
                  Email: ${templateParams.email}\n
                  Description du projet: ${templateParams.project_description}\n
                  Budget: ${templateParams.budget}\n
                  Discord: ${templateParams.discord}`
      };
    }

    // Send the data to the Discord webhook
    fetch(discordWebhookURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(discordMessage)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });

    e.target.reset();
  };


  return (
    <div className="contact-content" style={{ backgroundImage: `url(${backgroundImg})` }}>
      <div className="contact-container">
      {formType === 'contact' && (
        <>
          <h2>Nous Contacter</h2>
          <p>N'hésitez pas à me joindre pour échanger, collaborer ou simplement faire connaissance !</p>
        </>
        )} 
      {formType === 'quote' && (
        <>
          <h2>Demande de devis</h2>
          <p>N'hésitez pas à me joindre pour échanger, collaborer ou simplement faire connaissance !</p>
        </>
        )} 
        <div className="button-container">
          <button 
            className={`form-toggle-button ${formType === 'contact' ? 'active' : ''}`} 
            onClick={() => setFormType('contact')}
          >
            Contact
          </button>
          <button 
            className={`form-toggle-button ${formType === 'quote' ? 'active' : ''}`} 
            onClick={() => setFormType('quote')}
          >
            Demande de devis
          </button>
        </div>
        {formType === 'contact' && (
          <form className="contact-form" onSubmit={handleFormSubmit}>
            <div className="input-row">
              <div className="input-group">
                <label>Nom:</label>
                <input type="text" name="name" placeholder="Nom" required />
              </div>
              <div className="input-group">
                <label>Prénom:</label>
                <input type="text" name="prenom" placeholder="Prénom" required />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label>Adresse Mail:</label>
                <input type="email" name="email" placeholder="Adresse Mail" required />
              </div>
              <div className="input-group">
                <label>Objet:</label>
                <input type="text" name="subject" placeholder="Objet" required />
              </div>
            </div>
            <label>Message:</label>
            <textarea name="message" placeholder="Votre message" required></textarea>
            <button type="submit">Envoyer</button>
          </form>
        )}
        {formType === 'quote' && (
          <form className="quote-form" onSubmit={handleFormSubmit}>
            <div className="input-row">
              <div className="input-group">
                <label>Nom:</label>
                <input type="text" name="prenom" placeholder="Nom" required />
              </div>
              <div className="input-group">
                <label>Prénom:</label>
                <input type="text" name="name" placeholder="Prénom" required />
              </div>
            </div>
            <div className="input-row">
              <div className="input-group">
                <label>Discord:</label>
                <input type="text" name="discord" placeholder="Discord" />
              </div>
              <div className="input-group">
                <label>Email:</label>
                <input type="email" name="email" placeholder="Email" required />
              </div>
            </div>
            <label>Nom du projet:</label>
            <input type="text" name="project_name" placeholder="Nom du projet" required />
            <label>Description du projet:</label>
            <textarea name="project_description" placeholder="Description du projet" required></textarea>
            <label>Budget:</label>
            <input type="text" name="budget" placeholder="Budget" required />

            <label>Cahier des charges (upload):</label>
            <input type="file" name="document" accept=".pdf,.doc,.docx" />
            <button type="submit">Envoyer</button>
          </form>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default Contact;
