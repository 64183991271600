
import React from 'react';
import { Link } from 'react-router-dom';
import './produits.css';
import backgroundImg from '../baground_section_2.webp';
import produitsData from '../data.json';

const PageProduit = () => {
    const produits = produitsData.produits;

    return (
        <div className="products-page" style={{ backgroundImage: `url(${backgroundImg})` }}>
            <div className="products">
                <h1>Nos Produits</h1>
                <p>Découvrez notre gamme de produits, conçus pour offrir des solutions performantes et innovantes pour vos besoins. Chacun de nos scripts est développé avec soin et rigueur pour garantir une qualité optimale.</p>
                <div className="products-grid">
                    {produits.map(produit => (
                        <Link to={`/produit/${produit.name}`} key={produit.name} className="products-item-link">
                            <div className="products-item">
                                <img src={produit.images[0]} alt={produit.title} />
                                <div className="product-info">
                                    <h3>{produit.title}</h3>
                                    <div className="price">
                                        <span className="original-price">{produit.price}</span>
                                        <span className="discount-price">{produit.discountPrice}€</span>
                                    </div>
                                </div>
                                <div className="tags">
                                    {produit.tag.map((tag, i) => (
                                        <span key={i} className="tag">{tag}</span>
                                    ))}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PageProduit;
