import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Navbar from './components/NavBar';
import BarTopInfo from './components/BarTopInfo';
import Footer from './components/footer';
import Home from './pages/Home';
import LegalMention from './pages/LegalMentions';
import CGU from './components/CGU';
import CGV from './components/CGV';
import PatchNote from './pages/PatchNote';
import Portfolio from './pages/Portfolio';
import DevisPage from './pages/Contact';
import PageProduit from './pages/Produits';
import ProduitDetail from './components/ProduitsDetails';
import ProjectDetail from './components/ProjectDetail';
import CheckoutForm from './components/CheckoutForm';
import './pages/i18next';
import './App.css';

const stripePromise = loadStripe('pk_live_51OQuHjLC0NpJ3lHIUq3PzBDYnOBg9ryyVbZUrJqyYqr1oMFs1tiBohlepr02O8dsgm3QyYd6cnuT4jcRjVAYr9eC007kI1mDkW'); // Utilisez pk_test_... en développement

function App() {
  const announcementText = "🎉 SUMMER SALE -15% avec le code : SUMMER24";
  const linkannouncement = "#";
  const [token, setToken] = useState(null);

  return (
    <Router>
      <div className="App">
        <BarTopInfo texte={announcementText} href={linkannouncement} />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/mentions-legales" element={<LegalMention />} />
          <Route path='/CGU' element={<CGU />} />
          <Route path='/CGV' element={<CGV />} />
          <Route path='/patch-note' element={<PatchNote />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/produit/:name' element={<ProduitDetail />} />
          <Route path='/produits' element={<PageProduit />} />
          <Route path='/project/:name' element={<ProjectDetail />} />
          <Route path='/contact' element={<DevisPage />} />
          <Route path="/checkout/:id" element={<Elements stripe={stripePromise}><CheckoutForm /></Elements>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
