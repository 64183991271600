import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Partner.css'; 
import produitsData from '../data.json'
import { useTranslation } from 'react-i18next';


const Partner = () => {
  const { t, i18n } = useTranslation();
  const partenaires = produitsData.partner

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 3000, 
    arrows: false, 
    centerPadding: "20%",
    centerMode: false,
    responsive: [
      {
        breakpoint: 768,
        settings: { 
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "25%",
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        }
      }
    ]
  };

  return (
    <div className="partenaires-carousel">
      <p className="description">{t('partner.desc')}</p>
      <h2>{t('partner.title')}</h2>
      <Slider {...settings}>
        {partenaires.map((partenaire) => (
          <div key={partenaire.id} className="partenaire-item">
            <img src={partenaire.logo} alt={partenaire.nom} className="partenaire-logo" />
          </div>
          // <div key={partenaire.id} className="partenaire-item">
          //   <a href={partenaire.link} target="_blank"><img src={partenaire.logo} alt={partenaire.nom} className="partenaire-logo" /></a>
          // </div>
        ))}
      </Slider>
    </div>
  );
}

export default Partner;
