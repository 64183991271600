// src/pages/LegalMentions.js
import React from 'react';
import LegalMentions from '../components/LegalMentions';

const LegalMention = () => {
  document.title = "Mentions Légal - NoxZi-Dev";
  return (
    <div>
     <LegalMentions/>
    </div>
  );
};

export default LegalMention;
