import React from 'react';
import './LegalMentions.css';
import { Link } from 'react-router-dom';

const LegalMentions = () => {
  return (
    <div className="content-mentions">
    <div className="legal-mentions">
      <h1>Mentions Légales</h1>
      <section>
        <h2>Éditeur du Site</h2>
        <p>
          Nom: NoxZi-Dev<br />
          Adresse: 12 rue de l'Entreprise, 75001 Paris, France<br />
          Email: <a href="mailto:contact@noxzi-dev.fr">contact@noxzi-dev.fr</a> <br />
          N° SIRET: 981 685 662 000 18<br />
          Code APE: 4791A<br />
          RCS: Chambery A 981 685 662<br />
          Directeur de la publication: Mr.Faure
        </p>
      </section>
      <section>
        <h2>Hébergement</h2>
        <p>
          Hébergeur: LWS (Ligne Web Services)<br />
          Adresse: 10 Rue Penthièvre, 75008 Paris, France<br />
          Téléphone: +33 1 77 62 30 03<br />
        </p>
      </section>
      <section>
        <h2>Propriété Intellectuelle</h2>
        <p>
          Les contenus présents sur ce site (textes, images, vidéos, etc.) sont la propriété exclusive de NoxZi-Dev. Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord écrit de Jean Dupont.
        </p>
      </section>
      <section>
        <h2>Protection des Données</h2>
        <p>
          Les données personnelles collectées sur ce site sont utilisées uniquement dans le cadre légal prévu en France pour le respect de la vie privée. Les informations collectées ne sont jamais partagées avec des tiers. Vous disposez d'un droit d'accès, de rectification et de suppression des données vous concernant, que vous pouvez exercer en nous contactant à l'adresse email ci-dessus.
        </p>
      </section>
      <section>
        <h2>Cookies</h2>
        <p>
          Ce site utilise des cookies pour améliorer l'expérience utilisateur et pour des mesures d'audience. En continuant à naviguer sur ce site, vous acceptez l'utilisation des cookies.
        </p>
      </section>
      <section>
        <h2>Conditions d'Utilisation</h2>
        <p>
          L'utilisation de ce site implique l'acceptation pleine et entière des <Link to='/CGU'>conditions générales d'utilisation</Link>. Ces conditions d'utilisation peuvent être modifiées à tout moment, sans préavis, alors nous vous invitons à les consulter régulièrement.
        </p>
      </section>
      <section>
        <h2>Sources des Images</h2>
        <p>
          Certaines images utilisées sur ce site proviennent de FontAwesome et LottieFiles. Ces images sont utilisées conformément à leurs conditions de licence respectives.
        </p>
      </section>
    </div>
    </div>
  );
};

export default LegalMentions;
