import React from "react";
import PatchNotes from "../components/PatchNote";

const PatchNote = () => {
    document.title = "Patch Note - NoxZi-Dev";
    return (
        <div>
            <PatchNotes/>
        </div>
    )
}

export default PatchNote;