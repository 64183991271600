import React, { useEffect, useState } from 'react';
import './MainContent.css';
import backgroundImage from '../background.webp';
import feuilleImg from '../feuille.png';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const MainContent = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
      const handleScroll = () => {
          setScrollPosition(window.scrollY);
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  const fixedOffset = 79.3;

  return (
    <div className="main-content">
      <div className="background-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
          {/* <img
            className="scroll-image"
            style={{ transform: `translateY(calc(-${scrollPosition * 0.75}px - ${fixedOffset}px))` }}
            src={feuilleImg}
            alt=""
        /> */}
        <div className="overlay">
          <h1>NoxZi-Dev</h1>
          <h2>Developpement simply</h2>
          <Link to='/produits'>{t('Home.btn_discover_produits')}</Link>
        </div>
      </div>
    </div>
  );
}

export default MainContent;
