import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import produitsData from '../data.json';
import backgroundImg from '../baground_section_2.webp';
import './ProduitsDetails.css';
import Lottie from 'lottie-react';
import animationDataApplePay from '../apple-pay.json';
import animationDataGooglePay from '../google-pay.json';
import animationDataVisa from '../visa-card.json';
import animationDataMasterCard from '../mastercard.json';
import animationDataPaypal from '../paypal.json';
import animationDataCreditCard from '../credit-card.json';

const ProduitDetail = () => {
    const { name } = useParams();
    const navigate = useNavigate();
    const produit = produitsData.produits.find(p => p.name === name);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    if (!produit) {
        return <div>Produit non trouvé</div>;
    }

    const nextImage = () => {
        setCurrentImageIndex((currentImageIndex + 1) % produit.images.length);
    };

    const prevImage = () => {
        setCurrentImageIndex((currentImageIndex - 1 + produit.images.length) % produit.images.length);
    };

    return (
        <div className="produit-content" style={{ backgroundImage: `url(${backgroundImg})` }}>
            <div className="produit-detail">
                <button className="back-button" onClick={() => navigate('/produits')}>Retour</button>
                <div className="produit-title-detail">
                    <h1>{produit.title}</h1>
                </div>
                <div className="image-viewer">
                    <button className="nav-button left" onClick={prevImage}>&lt;</button>
                    <img src={produit.images[currentImageIndex]} alt={`${produit.title} ${currentImageIndex + 1}`} />
                    <button className="nav-button right" onClick={nextImage}>&gt;</button>
                </div>
                <div className="produit-description">
                    <div className="price-and-buy">
                        <p><strong>Prix:</strong> <span className="original-price">{produit.price}</span> <span className="discount-price">{produit.discountPrice}€</span></p>
                        <a href={produit.buyLink}>
                            <button className="buy-button">Acheter</button>
                        </a>
                    </div>
                    <div className="icon-paiement">
                        <div style={{ width: '25px', height: '25px' }}>
                            <Lottie animationData={animationDataApplePay} />
                        </div>
                        <div style={{ width: '25px', height: '25px' }}>
                            <Lottie animationData={animationDataVisa} />
                        </div>
                        <div style={{ width: '25px', height: '25px' }}>
                            <Lottie animationData={animationDataMasterCard} />
                        </div>
                        <div style={{ width: '25px', height: '25px' }}>
                            <Lottie animationData={animationDataPaypal} />
                        </div>
                        <div style={{ width: '25px', height: '25px' }}>
                            <Lottie animationData={animationDataGooglePay} />
                        </div>
                        <div style={{ width: '25px', height: '25px' }}>
                            <Lottie animationData={animationDataCreditCard} />
                        </div>
                    </div>
                    <p>{produit.description}</p>
                    <h3>Caractéristiques :</h3>
                    <ul className="features-list">
                    {produit.features.map((feature, index) => (
                        <React.Fragment key={index}>
                            <li>{feature}</li>
                            <br />
                        </React.Fragment>
                    ))}
                    </ul>
                </div>
                <div className="produit-info">
                    <p><strong>Langages utilisés:</strong> {produit.language}</p>
                    <p><strong>Framework:</strong> {produit.framework}</p>
                    <p><strong>Requis:</strong> {produit.required}</p>
                    <p><strong>Tags:</strong> {produit.tag.join(', ')}</p>
                    <p><strong>Documentation:</strong> <a href={produit.docLink} target="_blank" rel="noopener noreferrer">Voir la documentation</a></p>
                    <p><strong>Preview:</strong> <a href={produit.preview} target="_blank" rel="noopener noreferrer">Voir la vidéo</a></p>
                </div>
            </div>
        </div>
    );
};

export default ProduitDetail;
