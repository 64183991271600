import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './AvisCarousel.css';
import produitsData from '../data.json'
import { useTranslation } from 'react-i18next';

const AvisCarousel = () => {
  const { t, i18n } = useTranslation();
  const avis = produitsData.avis
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1, 
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    centerPadding: "35%",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
        }
      }
    ]
  };

  return (
    <div className="avis-carousel">
      <h2>{t('avis.title')}</h2>
      <p className="description">{t('avis.desc')}</p>
      <Slider {...settings}>
        {avis.map((item) => (
          <div key={item.id}>
            <div className="avis-item">
              <div className="avis-header">
                <h3>{item.nom}</h3>
                <div className="avis-rating">
                  {Array.from(Array(item.note), (e, i) => (
                    <span key={i} className="star">&#9733;</span>
                  ))}
                  {Array.from(Array(5 - item.note), (e, i) => (
                    <span key={i + item.note} className="star">&#9734;</span>
                  ))}
                </div>
              </div>
              <p className="avis-text">{item.commentaire}</p>
              <div className="avis-source">
                Source: {item.source} - {item.date}
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default AvisCarousel;

