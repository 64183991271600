import React from 'react';
import './SocialContent.css';
import backgroundImage from '../background.webp';
import Lottie from 'lottie-react';
import animationDataDiscord from '../discord.json';
import animationDataGithub from '../Github.json';
import animationDataInstagram from '../Instagram.json'
import animationDataYoutube from '../Youtube.json'
import { useTranslation } from 'react-i18next';

const SocialContent = () => {
    const { t, i18n } = useTranslation();
    return (
      <div className="social-content">
        <h2>{t('Home.join_social')}</h2>
        <div className="icon-container">
          <div style={{ width: '80px', height: '80px' }}>
            <a href="https://discord.gg/UC7ecCCWwK" target='_blank'><Lottie animationData={animationDataDiscord} /></a>
          </div>
          <div style={{ width: '80px', height: '80px' }}>
            <a href="https://github.com/NoxZi-Dev" target='_blank'><Lottie animationData={animationDataGithub} /></a>
          </div>
          <div style={{ width: '80px', height: '80px' }}>
            <a href="https://www.instagram.com/noxzidev/" target='_blank'><Lottie animationData={animationDataInstagram} /></a>
          </div>
          <div style={{ width: '80px', height: '80px' }}>
            <a href="https://www.youtube.com/@noxzi-dev" target='_blank'><Lottie animationData={animationDataYoutube} /></a>
          </div>
        </div>
      </div>
    );
}

export default SocialContent;
