import React from 'react';
import MainContent from '../components/MainContent';
import SocialContent from '../components/SocialContent';
import Stats from '../components/stats';
import About from '../components/About';
import AvisCarousel from '../components/AvisCarousel';
import Partner from '../components/Partner';
import DerniersProduits from '../components/DerniersProduits';
import SectionDevisEtPortfolio from '../components/SectionDevisEtPortfolio';

const Home = () => {
    const privateCreations = 80;
    const deliveredResources = 225;
    const numberOfCustomers = 115;
    document.title = "NoxZi-Dev";

    
  return (
    <div>
      <MainContent />
      <SocialContent />
      <About/>
      <AvisCarousel />
      <DerniersProduits />
      <Stats
        privat={privateCreations}
        delivred={deliveredResources}
        customer={numberOfCustomers}
      />
      <SectionDevisEtPortfolio/>
      <Partner/>
    </div>
  );
};

export default Home;
