import React from 'react';
import './Stats.css';
import { useTranslation } from 'react-i18next';


const Stats = ({privat, delivred, customer}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="stats-content">
         <h2>{t('stats.title')}</h2>
        <div className="stats-container">
      <div className="stat-item">
        <div className="stat-title">{t('stats.title_crea')}</div>
        <div className="stat-value">{privat}</div>
      </div>
      <div className="stat-item">
        <div className="stat-title">{t('stats.title_nb_livred')}</div>
        <div className="stat-value">{delivred}</div>
      </div>
      <div className="stat-item">
        <div className="stat-title">{t('stats.title_nb_customer')}</div>
        <div className="stat-value">{customer}</div>
      </div>
    </div>
    </div>
  );
};

export default Stats;
