import React from "react";
import './CGU.css';

const CGU = () => {
    return (
        <div className="content-CGU">
            <div className="conditions-general-utilisations">
            <div>
    <h1>Mentions Légales</h1>
  </div>
  <div>
    <section>
      <h2>Conditions Générales d'Utilisation</h2>
      <p>
        Bienvenue sur notre site web. En naviguant sur ce site, vous acceptez les conditions générales d'utilisation suivantes. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser ce site.
      </p>
    </section>
  </div>
  <div>
    <section>
      <h2>1. Utilisation du Site</h2>
      <p>
        1.1. Vous êtes autorisé à accéder et à utiliser notre site web et son contenu à des fins personnelles et non commerciales.
      </p>
      <p>
        1.2. Vous acceptez de ne pas utiliser ce site à des fins illégales ou interdites par la loi.
      </p>
    </section>
  </div>
  <div>
    <section>
      <h2>2. Propriété Intellectuelle</h2>
      <p>
        2.1. Les contenus présents sur ce site, y compris mais sans s'y limiter, les textes, les graphiques, les logos, les images, les clips audio et vidéo, sont la propriété de NoxZi-Dev ou de ses concédants de licence, et sont protégés par les lois françaises et internationales sur les droits d'auteur et la propriété intellectuelle.
      </p>
      <p>
        2.2. Vous acceptez de ne pas copier, reproduire, modifier, distribuer, afficher, publier, vendre, concéder sous licence, créer des œuvres dérivées, ou exploiter de quelque manière que ce soit tout contenu de ce site sans l'autorisation préalable écrite de NoxZi-Dev.
      </p>
    </section>
  </div>
  <div>
    <section>
      <h2>3. Protection des Données Personnelles</h2>
      <p>
        3.1. Nous collectons et utilisons vos données personnelles conformément à notre politique de confidentialité. En utilisant ce site, vous consentez à la collecte et à l'utilisation de vos données personnelles telles que décrites dans cette politique.
      </p>
    </section>
  </div>
  <div>
    <section>
      <h2>4. Liens vers d'Autres Sites</h2>
      <p>
        4.1. Notre site peut contenir des liens vers des sites web de tiers. Ces liens sont fournis uniquement pour votre commodité. NoxZi-Dev n'a aucun contrôle sur ces sites tiers et n'est pas responsable de leur contenu. L'inclusion de liens vers ces sites tiers n'implique pas une approbation de leur part par NoxZi-Dev.
      </p>
    </section>
  </div>
  <div>
    <section>
      <h2>5. Limitation de Responsabilité</h2>
      <p>
        5.1. NoxZi-Dev s'efforce de fournir des informations précises et à jour sur ce site, mais ne donne aucune garantie quant à l'exactitude, l'exhaustivité ou la pertinence des informations fournies.
      </p>
      <p>
        5.2. En aucun cas NoxZi-Dev, ni ses dirigeants, employés ou partenaires, ne seront responsables des dommages directs, indirects, accessoires, spéciaux, consécutifs ou punitifs résultant de l'utilisation de ce site ou de tout contenu disponible sur ce site.
      </p>
    </section>
  </div>
  <div>
    <section>
      <h2>6. Modifications des Conditions Générales d'Utilisation</h2>
      <p>
        6.1. Nous nous réservons le droit de modifier ces conditions générales d'utilisation à tout moment et sans préavis. Vous êtes invité à consulter régulièrement cette page pour prendre connaissance des modifications éventuelles.
      </p>
    </section>
  </div>
  <div>
    <section>
      <h2>7. Droit Applicable et Juridiction Compétente</h2>
      <p>
        7.1. Ces conditions générales d'utilisation sont régies et interprétées conformément aux lois françaises. En cas de litige, les tribunaux français seront seuls compétents.
      </p>
    </section>
  </div>
            </div>
        </div>
    )
}

export default CGU;