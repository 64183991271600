import React from "react";
import Produit from "../components/produits";
import FAQ from "../components/FAQ";
import produitsData from '../data.json'

const PageProduit = () => {
    document.title = "Produits - NoxZi-Dev";
    const faqDataProduit = produitsData.FAQproduit

    return(
        <div>
            <Produit/>
            <FAQ data={faqDataProduit} />
        </div>
    )
}

export default PageProduit