import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './portfolio.css';
import backgroundImg from '../baground_section_about.webp';
import produitsData from '../data.json';

const Portfolio = () => {
    const projects = produitsData.portfolio;

    return (
        <div className="portfolio-page" style={{ backgroundImage: `url(${backgroundImg})` }}>
            <div className="portfolio">
                <div className="title-portfolio">
                    <h1 className='title-page-portfolio'>Notre Portfolio</h1>
                </div>
                <p>Découvrez notre portfolio, une vitrine de notre expertise et de notre créativité. Chaque projet que nous avons réalisé est le fruit d'une collaboration étroite avec nos clients, visant à transformer leurs idées en solutions numériques innovantes et performantes.</p>                    
                <p>Que ce soit pour des sites web ou des scripts personnalisés pour FiveM, nous mettons un point d'honneur à délivrer des résultats de haute qualité.</p>
                <p>Explorez nos réalisations pour voir comment nous avons aidé nos clients à atteindre leurs objectifs et à se démarquer dans leur domaine.</p>
                <div className="portfolio-grid">
                    {projects.map((project) => (
                        <Link to={`/project/${project.name}`} key={project.name} className='project-item-list'>
                            <div className="portfolio-item">
                               <img src={project.images[0]} alt={project.title} />
                               <div className="project-infor">
                                    <h3>{project.title}</h3>
                                    <div className="customer">
                                        <span className="customer-name">{project.client}</span>
                                    </div>
                                </div>
                                <div className="project-tags">
                                    {project.tag.map((tag, i) => (
                                        <span key={i} className="tag">{tag}</span>
                                    ))}
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
