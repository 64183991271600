import React from "react";
import './BarTopInfo.css';

const BarTopInfo = ({texte, href}) => {
    return (
        <a href={href} target="_blank"><div className="anounce-bar">
            <h3>{texte}</h3>
        </div></a>
    );
}

export default BarTopInfo;
