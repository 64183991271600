import React from 'react';
import './footer.css';
import logo from '../logo-site-1.png'
import '@fortawesome/fontawesome-free/css/all.min.css';
import Lottie from 'lottie-react';
import animationDataDiscord from '../discord.json';
import animationDataGithub from '../Github.json';
import animationDataInstagram from '../Instagram.json'
import animationDataYoutube from '../Youtube.json'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// const { t, i18n } = useTranslation();

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo-section">
          <img src={logo} alt="Logo" className="footer-logo" />
          <div className="footer-social">
          <div style={{ width: '40px', height: '40px' }}>
            <a href="https://discord.gg/UC7ecCCWwK" target='_blank'><Lottie animationData={animationDataDiscord} /></a>
          </div>
          <div style={{ width: '40px', height: '40px' }}>
            <a href="https://github.com/NoxZi-Dev" target='_blank'><Lottie animationData={animationDataGithub} /></a>
          </div>
          <div style={{ width: '40px', height: '40px' }}>
            <a href="https://www.instagram.com/noxzidev/" target='_blank'><Lottie animationData={animationDataInstagram} /></a>
          </div>
          <div style={{ width: '40px', height: '40px' }}>
            <a href="https://www.youtube.com/@noxzi-dev" target='_blank'><Lottie animationData={animationDataYoutube} /></a>
          </div>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <h4>{t("foooter.shortcuts.title")}</h4>
            <ul>
              <li><Link to="/">{t("foooter.shortcuts.home")}</Link></li>
              <li><Link to="/produits">{t("foooter.shortcuts.products")}</Link></li>
              <li><Link to="/portfolio">{t("foooter.shortcuts.portfolio")}</Link></li>
              <li><Link to="/patch-note">{t("foooter.shortcuts.patch-note")}</Link></li>
              <li><Link to="/contact">{t("foooter.shortcuts.devis")}</Link></li>
              <li><a href="https://doc.noxzi-dev.fr">{t("foooter.shortcuts.doc")}</a></li>
            </ul>
          </div>
          {/* <div className="footer-column">
            <h4>Product</h4>
            <ul>
              <li>Integrated Platform</li>
              <li>Payroll</li>
              <li>Human Resources</li>
              <li>Workforce Management</li>
              <li>Recruiting & Onboarding</li>
            </ul>
          </div> */}
          <div className="footer-column">
            <h4>{t("foooter.legal.title")}</h4>
            <ul>
              <li><Link to="/mentions-legales">{t("foooter.legal.mention-legales")}</Link></li>
              <li><Link to="/CGU">{t("foooter.legal.CGU")}</Link></li>
              <li><Link to="/CGV">{t("foooter.legal.CGV")}</Link></li>
              <a href=""><img src="https://images.dmca.com/Badges/DMCA_logo-grn-btn100w.png?ID=e37e12c4-e870-4fbc-a71a-1014ec8c8808" alt="DMCA.com Protection Status"/></a>
              {/* <li>Newsroom</li>
              <li>Contact Us</li> */}
            </ul>
          </div>
        </div>
        <div className="footer-contact">
          <div>
            <p>NoxZi-Dev</p>
            <p>"Developpement simply"</p>
            <p><a href="mailto:contact@noxzi-dev.fr" target='_blank'>contact@noxzi-dev.fr</a></p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>©2024-2023 NoxZi-Dev, {t('foooter.allright')}</p>
      </div>
    </footer>
  );
}

export default Footer;
