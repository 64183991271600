import React from 'react';
import './DerniersProduits.css'; // Fichier CSS pour le style
import ImgLastProduct from '../scritp.png'
import produitsData from '../data.json';
import backgroundImg from '../baground_section_4.webp'
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DerniersProduits = () => {
  const { t, i18n } = useTranslation();
  const produits = produitsData.lastProduct
  const produit = produits[0];

  return (
    <div className="produits-section" style={{ backgroundImage: `url(${backgroundImg})` }}>
    <p className="description">{t('lastproduct.desc')}</p>
    <h2>{t('lastproduct.title')}</h2>
    <div className="produit-item">
      <img src={produit.image} alt={produit.nom} className="produit-image" />
      <h3 className='white'>{produit.nom}</h3>
      <Link to={`/produit/`+ produit.url} className="produit-cta">{t('lastproduct.btn')}</Link>
    </div>
  </div>
  );
}

export default DerniersProduits;
