import React from "react";
import './CGV.css';

const CGV = () => {
    return (
        <div className="content-CGV">
            <div className="conditions-general-ventes">
                <div>
                    <h1>Mentions Légales</h1>
                </div>
                <div>
                    <section>
                        <h2>Conditions Générales de Vente</h2>
                        <p>
                            Bienvenue sur notre site web. En naviguant sur ce site, vous acceptez les conditions générales de vente suivantes. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser ce site.
                        </p>
                    </section>
                </div>
                <div>
                    <section>
                        <h2>1. Champ d'Application</h2>
                        <p>
                            1.1. Les présentes conditions générales de vente s'appliquent à toutes les commandes de scripts numériques passées sur notre site web.
                        </p>
                        <p>
                            1.2. En passant commande, vous acceptez sans réserve les présentes conditions générales de vente.
                        </p>
                    </section>
                </div>
                <div>
                    <section>
                        <h2>2. Commandes</h2>
                        <p>
                            2.1. Toutes les commandes sont passées via notre site web. Nous nous réservons le droit de refuser toute commande pour des raisons légitimes.
                        </p>
                        <p>
                            2.2. Vous recevrez un email de confirmation de votre commande une fois celle-ci validée.
                        </p>
                    </section>
                </div>
                <div>
                    <section>
                        <h2>3. Prix et Paiement</h2>
                        <p>
                            3.1. Les prix sont indiqués en euros, toutes taxes comprises (TTC).
                        </p>
                        <p>
                            3.2. Le paiement est exigible immédiatement à la commande. Vous pouvez payer par carte bancaire ou tout autre moyen de paiement proposé sur notre site.
                        </p>
                    </section>
                </div>
                <div>
                    <section>
                        <h2>4. Livraison Numérique</h2>
                        <p>
                            4.1. Les scripts sont livrés par voie numérique à l'adresse email que vous avez indiquée lors de votre commande dans un délai de 12 heures.
                        </p>
                    </section>
                </div>
                <div>
                    <section>
                        <h2>5. Rétractation et Retours</h2>
                        <p>
                            5.1. En raison de la nature numérique des produits, aucune demande de remboursement ou de retour ne sera acceptée une fois le script livré.
                        </p>
                    </section>
                </div>
                <div>
                    <section>
                        <h2>6. Garanties et Responsabilité</h2>
                        <p>
                            6.1. Nos scripts sont fournis tels quels, sans garantie d'aucune sorte, expresse ou implicite.
                        </p>
                        <p>
                            6.2. Notre responsabilité ne pourra être engagée en cas de non-respect de la législation du pays où les scripts sont utilisés.
                        </p>
                    </section>
                </div>
                <div>
                    <section>
                        <h2>7. Modification des Conditions Générales de Vente</h2>
                        <p>
                            7.1. Nous nous réservons le droit de modifier les présentes conditions générales de vente à tout moment et sans préavis. Vous êtes invité à consulter régulièrement cette page pour prendre connaissance des modifications éventuelles.
                        </p>
                    </section>
                </div>
                <div>
                    <section>
                        <h2>8. Droit Applicable et Juridiction Compétente</h2>
                        <p>
                            8.1. Les présentes conditions générales de vente sont régies et interprétées conformément aux lois françaises. En cas de litige, les tribunaux français seront seuls compétents.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
}

export default CGV;
